<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Question utilisateur</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'productQuestions.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <dl class="row">
                  <dt class="col-4">Demandeur</dt>
                  <dd class="col-8">{{ author }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Produit</dt>
                  <dd class="col-8">
                    <router-link :to="{ name: 'products.edit', params: { id: productQuestion.productId } }">{{ productName }}</router-link>
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Date</dt>
                  <dd class="col-8">{{ productQuestion ? $longDateTime(productQuestion.createdAt) : '-' }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">{{ getProductQuestionStatusText(productQuestion.status) }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <h3 class="subtitle my-4">Question</h3>

        <div class="card">
          <div class="card-body">
            {{ productQuestion.question }}
          </div>
        </div>

        <h3 class="subtitle my-4">Réponse</h3>

        <div class="textarea-wrapper">
          <textarea class="form-control reply-input" v-model="productQuestion.answer" placeholder="Rédiger une réponse"></textarea>

          <div v-if="status === 'pending'" class="mt-2 text-muted">
            En cliquant sur 'Publier la réponse', l'auteur de la question recevra une notification par courriel et la réponse sera visible à tous sur la fiche du produit.
          </div>
        </div>

        <div class="mt-4" v-if="status === 'pending'">
          <button class="btn btn-primary" :disabled="!canSubmit" @click="submitAnswer">Publier la réponse</button>
          <button class="btn btn-link ml-2" @click="discardQuestion">Ignorer la question</button>
        </div>
        <div class="mt-4" v-else-if="status === 'discarded'">
          <button class="btn btn-primary" :disabled="!canSubmit" @click="submitAnswer">Publier la réponse</button>
        </div>
        <div class="mt-4" v-else>
          <button class="btn btn-primary" :disabled="!canSubmit" @click="submitAnswer">Enregistrer</button>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import userData from '@/mixins/user-data'
import productQuestionStatus from '@/mixins/productQuestionStatus'
import { removeHTMLTagsFromString } from '@/utils/regex'

export default {
  mixins: [formatsDate, userData, productQuestionStatus],
  data () {
    return {
      isProcessing: false
    }
  },
  computed: {
    productQuestion () {
      return this.$store.getters['productQuestions/getDetailsById'](this.$route.params.id) || {}
    },
    author () {
      if (this.productQuestion && this.productQuestion.user) {
        return `${this.productQuestion.user.firstName} ${this.productQuestion.user.lastName}`
      }
      return '-'
    },
    productName () {
      if (this.productQuestion && this.productQuestion.product) {
        return removeHTMLTagsFromString(this.productQuestion.product.name)
      }
      return '-'
    },
    status () {
      return this.productQuestion ? this.productQuestion.status : null
    },
    canSubmit () {
      return this.productQuestion && this.productQuestion.answer && !this.isProcessing
    }
  },
  methods: {
    fetchData () {
      this.$store.dispatch('productQuestions/fetchById', {
        id: this.$route.params.id,
        storeId: this.currentStoreId
      })
    },
    async submitAnswer () {
      this.isProcessing = true

      await this.$store.dispatch('productQuestions/reply', {
        id: this.$route.params.id,
        storeId: this.currentStoreId,
        answer: this.productQuestion.answer
      })

      this.$toasted.success('La réponse a bien été publiée.')
      this.$router.push({ name: 'productQuestions.index' })

      this.isProcessing = false
    },
    async discardQuestion () {
      this.isProcessing = true

      await this.$store.dispatch('productQuestions/discard', {
        id: this.$route.params.id,
        storeId: this.currentStoreId
      })

      this.$toasted.success('La question a été marquée comme ignorée.')
      this.$router.push({ name: 'productQuestions.index' })

      this.isProcessing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 1.2rem;
}

.message {
  white-space: pre-wrap;
}

.reply-input {
  height: 150px;
}
</style>
