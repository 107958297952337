import Vue from 'vue'

export default Vue.extend({
  methods: {
    getProductQuestionStatusText (value: string): string {
      switch (value) {
        case 'published':
          return 'Répondue'
        case 'discarded':
          return 'Ignorée'
        case 'removed':
          return 'Supprimée'
        case 'pending':
        default:
          return 'À traiter'
      }
    }
  }
})
