<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid product-questions-view">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Questions utilisateurs</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="productQuestions"
          :display-text-filter="false"
          :display-status-filter="true"
          :list-status-filter="getStatusList"
          :display-reset="true"
          @changePage="changePage"
        />

        <div class="card mb-4">
          <div class="card-body p-0 orders-table">
            <collection-table
              :cols="cols"
              :list-items="productQuestions"
              no-top-border
              :show-actions="false"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              hover
              :link="(item) => `/product-questions/${item.id}`"
              :empty-img-src="require('@/assets/images/icons/empty.svg')"
              empty-message="Les questions des utilisateurs s'afficheront ici."
              :row-class="rowClass"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="table-pagination">
              <pagination state="productQuestions"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :range="2"
                          :display-per-page="true"
                          @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import productQuestionStatus from '@/mixins/productQuestionStatus'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import pagination from '@/mixins/pagination'
import { removeHTMLTagsFromString } from '@/utils/regex'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [userData, formatsDate, productQuestionStatus, pagination],
  computed: {
    getStatusList () {
      return [
        {
          value: 'pending',
          label: 'A traiter'
        },
        {
          value: 'published',
          label: 'Répondues'
        },
        {
          value: 'discarded',
          label: 'Ignorées'
        }
      ]
    },
    cols () {
      return [
        {
          header: 'Date',
          property: 'createdAt',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.createdAt)
          }
        },
        {
          header: 'Utilisateur',
          property: 'user.firstName',
          method: (item) => {
            return `${get(item, 'user.firstName')} ${get(item, 'user.lastName')}`
          }
        },
        {
          header: 'Produit',
          property: 'product.name',
          method: (item) => {
            const name = get(item, 'product.name', '-')
            return removeHTMLTagsFromString(name)
          }
        },
        {
          header: 'Statut',
          property: 'status',
          method: (item) => {
            return this.getProductQuestionStatusText(item.status)
          }
        }
      ]
    },
    rowClass () {
      return (productQuestion) => {
        return productQuestion.status === 'pending' ? 'row-pending' : ''
      }
    },
    productQuestions () {
      return this.paginatedData.data
    }
  },
  methods: {
    async changePage (page) {
      await this.fetchData(page)
    },
    async fetchData (page) {
      await this.$store.dispatch('productQuestions/fetch', { page, storeId: this.currentStoreId })
    }
  },
  async created () {
    this.instance = 'productQuestions'
    await this.fetchData()
  }
}
</script>

<style lang="scss">
/* Do not apply 'scoped' here, cause we need to style collection-table rows */

.product-questions-view {
  .row-pending {
    &:not(:hover) {
      background: $gray-100;
    }

    td {
      font-weight: 700;
    }
  }
}
</style>
